export function videoAutoplay() {
  const videoBlocks = document.querySelectorAll('.background-item');

  videoBlocks.forEach((block) => {
    const parentElement = block.parentElement;
    const mobileVideo = parentElement.querySelector('.background-item-mobile');
    const desktopVideo = parentElement.querySelector('.background-item:not(.background-item-mobile)');
    const isMobile = mobileVideo && window.innerWidth < 768;

    function playVideo(video) {
      if (!video) return;
      const source = video.querySelector('source');
      if (!source) return;
      const dataSrc = source.dataset.src;
      const currentSrcUrl = new URL(source.src, window.location.href).href;
      const dataSrcUrl = new URL(dataSrc, window.location.href).href;

      if (currentSrcUrl !== dataSrcUrl) {
        const isBackgroundItemMobile = video.classList.contains('background-item-mobile');
        if ((isMobile && isBackgroundItemMobile) || (!isMobile && !isBackgroundItemMobile)) {
          source.src = dataSrc;
          source.parentElement.load();
        }
      }
    }

    playVideo(mobileVideo);
    playVideo(desktopVideo);
  });
}
